import {
	DateFormat,
	FontSizeEnum,
	HeaderColor,
	HighMediumLow,
	MapThemeEnum,
	MapViewTypeEnum,
	ScrollerOption,
	Theme,
	ThemeColor,
	TimeFormat,
	VeryhighToVerylow,
	VisualizerColorTheme,
} from '../settings';
import { Nullable } from '../typescript';
import { WikimediaLanguageCodeEnum } from '../wikimedia';
import { Language } from './language.enum';

export enum UserPreferencesKey {
	GENERAL_THEME = 'general.theme',
	GENERAL_THEME_COLOR = 'general.themeColor',
	GENERAL_SIDEBAR = 'general.sidebar',
	GENERAL_SIDEBAR_OPENED = 'general.sidebarOpened',
	GENERAL_SCROLLER = 'general.scroller',
	GENERAL_LANGUAGE = 'general.language',
	GENERAL_DATE_FORMAT = 'general.dateFormat',
	GENERAL_TIME_FORMAT = 'general.timeFormat',
	GENERAL_OCCASIONAL_SURVEYS = 'general.occasionalSurveys',
	GENERAL_BUG_REPORTER = 'general.bugReporter',
	GENERAL_SHOW_TIME_PREFIX = 'general.showTimePrefix',
	GENERAL_WEEK_DAY_LONG_FORMAT = 'general.weekDayLongFormat',
	VISUALIZER_COLOR_THEME = 'visualizer.colorTheme',
	VISUALIZER_GRAPH_3D = 'visualizer.in3D',
	VISUALIZER_RESOLUTION = 'visualizer.resolution',
	VISUALIZER_SMOOTH_EDGES = 'visualizer.smoothEdges',
	VISUALIZER_SHOW_ANIMATION = 'visualizer.showAnimation',
	VISUALIZER_LOADING_GRAPH_TIME = 'visualizer.loadingGraphTime',
	VISUALIZER_RELATIONSHIP_STRENGTH = 'visualizer.relationshipStrength',
	VISUALIZER_TRACK_PATH = 'visualizer.trackPath',
	VISUALIZER_NODE_HOVER = 'visualizer.nodeHover',
	VISUALIZER_GRAPH_CARD_TYPE = 'visualizer.graphCardType',
}

export enum EmailCategories {
	MARKETING = 'MARKETING',
	SYSTEM = 'SYSTEM',
}

export enum EmailSubcategories {
	// MARKETING
	FEATURES_AND_ANNOUNCEMENTS = 'featuresAndAnnouncements',
	TIPS_AND_RECOMMENDATIONS = 'tipsAndRecommendations',
	COMMUNITY = 'community',
	RESEARCH_AND_FEEDBACK = 'researchAndFeedback',
	NEWSLETTERS = 'newsletters',
	EVENTS_AND_MILESTONES = 'eventsAndMilestones',

	// SYSTEM
	GENERAL = 'general',
	INVITE_FRIEND = 'inviteFriend',
	PREMIUM_AND_PAYMENT = 'premiumAndPayment',
}

export const categoryToSubcategories = {
	[EmailCategories.MARKETING]: [
		EmailSubcategories.FEATURES_AND_ANNOUNCEMENTS,
		EmailSubcategories.TIPS_AND_RECOMMENDATIONS,
		EmailSubcategories.COMMUNITY,
		EmailSubcategories.RESEARCH_AND_FEEDBACK,
		EmailSubcategories.NEWSLETTERS,
		EmailSubcategories.EVENTS_AND_MILESTONES,
	],
	[EmailCategories.SYSTEM]: [
		EmailSubcategories.GENERAL,
		EmailSubcategories.INVITE_FRIEND,
		EmailSubcategories.PREMIUM_AND_PAYMENT,
	],
} as const;

export const subcategoryToCategory = {
	[EmailSubcategories.FEATURES_AND_ANNOUNCEMENTS]: EmailCategories.MARKETING,
	[EmailSubcategories.TIPS_AND_RECOMMENDATIONS]: EmailCategories.MARKETING,
	[EmailSubcategories.COMMUNITY]: EmailCategories.MARKETING,
	[EmailSubcategories.RESEARCH_AND_FEEDBACK]: EmailCategories.MARKETING,
	[EmailSubcategories.NEWSLETTERS]: EmailCategories.MARKETING,
	[EmailSubcategories.EVENTS_AND_MILESTONES]: EmailCategories.MARKETING,
	[EmailSubcategories.GENERAL]: EmailCategories.SYSTEM,
	[EmailSubcategories.INVITE_FRIEND]: EmailCategories.SYSTEM,
	[EmailSubcategories.PREMIUM_AND_PAYMENT]: EmailCategories.SYSTEM,
} as const;

export type SubscriptionPreferences = {
	[key in (typeof categoryToSubcategories)[EmailCategories.MARKETING][number]]: boolean;
};

export enum DomainSettingsEnum {
	NO_CREATION = 'NO_CREATION',
	ALL = 'ALL',
	ONLY_TOP = 'ONLY_TOP',
	PROPERTY = 'PROPERTY',
}

export enum DefaultWorkspaceSettingsEnum {
	LAST_USED = 'LAST_USED',
	BY_ID = 'BY_ID',
}

export enum WorkspaceListOrderByEnum {
	ALPHABETICAL = 'ALPHABETICAL',
	CREATED_AT = 'CREATED_AT',
	CONTAINED_NODES = 'CONTAINED_NODES',
}

export interface UserPreferencesGeneral {
	theme: Theme;
	themeColor: ThemeColor;
	headerColor: HeaderColor;
	sidebar: HighMediumLow;
	sidebarOpened: boolean;
	scroller: ScrollerOption;
	language: Language;
	dateFormat: DateFormat;
	timeFormat: TimeFormat;
	occasionalSurveys: boolean;
	bugReporter: boolean;
	usageAnalysis: boolean;
	showTimePrefix: boolean;
	weekDayLongFormat: boolean;
	emailSubscriptions: SubscriptionPreferences;
	pushSubscriptions: SubscriptionPreferences;
	fontSize: FontSizeEnum;
}

export interface UserPreferencesVisualizer {
	colorTheme: VisualizerColorTheme;
	in3D: boolean;
	resolution: VeryhighToVerylow;
	smoothEdges: boolean;
	showAnimation: boolean;
	loadingGraphTime: number;
	relationshipStrength: VeryhighToVerylow;
	trackPath: boolean;
	nodeHover: boolean;
	glare: boolean;
}

export interface UserPreferencesMap {
	mapTheme: MapThemeEnum;
	mapViewType: MapViewTypeEnum;
	showPlaces: boolean;
	showRoads: boolean;
	showTransportation: boolean;
}

export interface UserPreferencesNode {
	wikimediaLanguage: WikimediaLanguageCodeEnum;
	domainCreation: DomainSettingsEnum;
	defaultWorkspace: DefaultWorkspaceSettingsEnum;
	defaultWorkspaceId: Nullable<string>;
	workspaceListOrderBy: WorkspaceListOrderByEnum;
}

export interface UserPreferencesAI {
	inferredDescriptionCustomPrompt: Nullable<string>;
	reachVisionCustomPrompt: Nullable<string>;
}

export interface UserPreferences {
	general: UserPreferencesGeneral;
	map: UserPreferencesMap;
	visualizer: UserPreferencesVisualizer;
	node: UserPreferencesNode;
	ai: UserPreferencesAI;
}

export type UserPreferencesSubscriptions = Pick<
	UserPreferencesGeneral,
	'emailSubscriptions' | 'pushSubscriptions'
>;
