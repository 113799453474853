import { SharepointEntryInterface } from './sharepoint-integration-entry.interface';
import { SharepointIntegrationInterface } from './sharepoint-integration.interface';

export interface RedeemSharepointIntegrationAuthCodeRequestInterface {
	code: string;
}

export interface GetSharepointIntegrationAccessTokenRequestInterface {
	integrationId: SharepointIntegrationInterface['id'];
}

export interface GetSharepointIntegrationRemoteData {
	integrationId: SharepointIntegrationInterface['id'];
}

export interface SharepointIntegrationRemotePagination {
	skip?: string;
	size?: number;
}

export interface RegisterSharepointIntegrationRequestInterface {
	siteId: string;
	siteUrl: string;
	siteName: string;
	accessToken: string;
	refreshToken: string;
	expiresAt: number;
}

export interface ExecuteSharepointIntegrationRequestInterface {
	integrationId: SharepointIntegrationInterface['id'];
	entries: SharepointEntryInterface[];
}
