import { Nullable } from '../../typescript';
import { IntegrationBaseInterface } from '../integration-base.interface';

export interface SharepointIntegrationInterface extends IntegrationBaseInterface {
	isSync: boolean;
	syncDate: Nullable<Date>;
	siteId: string;
	siteUrl: string;
    accessToken?: string;
}
