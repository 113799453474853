import { Nullable } from '../../typescript';

export enum SharepointEntryTypeEnum {
	FOLDER = 0,
	FILE = 1,
	PAGE = 2,
}

export interface SharepointEntryInterface {
	id: string;
	type: SharepointEntryTypeEnum;
	driveId: Nullable<string>;
}
