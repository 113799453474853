import { JobStatusEnum, JobTypeEnum } from '../../jobs';
import { NodeSubtypesSettingsNodeMinimalInfo } from '../../node-subtypes-settings';
import { PipelineInterface, PipelinePayloadInterface, PipelineStatusEnum } from '../../pipelines';
import { Nullable } from '../../typescript';
import { IntegrationExecutionInterface } from '../integration-execution.interface';
import { SharepointIntegrationInterface } from './sharepoint-integration.interface';

export interface GenerateSharepointAuthUrlResponseInterface {
	url: string;
}

export interface RedeemSharepointAuthCodeResponseInterface {
	accessToken: string;
	refreshToken: string;
	expiresAt: Date;
}

export type GetSharepointAccessTokenResponseInterface = Omit<
	RedeemSharepointAuthCodeResponseInterface,
	'refreshToken'
>;

export interface RegisterSharepointIntegrationResponseInterface {
	integration: SharepointIntegrationInterface;
	nodesToCreateMap: Map<string, NodeSubtypesSettingsNodeMinimalInfo[]>;
}

export interface ExecuteSharepointIntegrationResponseInterface {
	execution?: Nullable<IntegrationExecutionInterface>;
	payload?: PipelinePayloadInterface;
	pipeline?: PipelineInterface;
	jobStates?: Map<
		JobTypeEnum,
		{
			id: string;
			jobStatus: JobStatusEnum;
			createdAt: Date;
			startedAt: Nullable<Date>;
			finishedAt: Nullable<Date>;
		}[]
	>;
	pipelineStatus?: PipelineStatusEnum;
	nodesToCreateMap?: Map<string, NodeSubtypesSettingsNodeMinimalInfo[]>;
}
