export enum ReachIntegration {
	BROWSER_PLUGIN = 'BROWSER_PLUGIN',
	CSV = 'CSV',
	PHONE_CONTACTS = 'PHONE_CONTACTS',
	POCKET = 'POCKET',
	EVERNOTE = 'EVERNOTE',
	ZAPIER = 'ZAPIER',
	GOOGLE_DOCS = 'GOOGLE_DOCS',
	DROPBOX_PAPER = 'DROPBOX_PAPER',
	QUIP = 'QUIP',
	GOOGLE_DRIVE = 'GOOGLE_DRIVE',
	SITEMAP = 'SITEMAP',
	SHAREPOINT = 'SHAREPOINT',
}
