import { Nullable } from '../../typescript';
import { IntegrationSyncSelectionInterface } from '../integration-sync-selection.interface';
import { ReachIntegration } from '../integration-type.enum';
import { SharepointEntryTypeEnum } from './sharepoint-integration-entry.interface';

export interface SharepointSyncSelectionInterface
	extends Omit<IntegrationSyncSelectionInterface, 'integrationType' | 'externalId' | 'path'> {
	integrationType: ReachIntegration.SHAREPOINT;
	externalId: NonNullable<Required<IntegrationSyncSelectionInterface['externalId']>>;
	driveId: Nullable<string>;
	type: SharepointEntryTypeEnum;
}
